import * as React from "react";
import FooterLinks from "../components/FooterLinks";
import HomePageStatic from "../components/HomePageStatic";
import Layout from "../components/Layout";
import Subscription from "../components/Subscription";
import "./index.css";
import "./index-mob.css";
import { StaticImage } from "gatsby-plugin-image"
import Login from "./login";
import { navigate } from 'gatsby';
import Loading from "./loading";

const IndexPage = () => {
  const [ isLoggedIn , setLogInStatus] = React.useState("false"); 

  React.useEffect(() => {
    navigate('/user/apps');
  }, [])

  return (
    <>
    </>
  )
}

export default IndexPage
